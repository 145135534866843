<template>
  <div class="live-chat-holder"></div>
</template>

<script>
const LiveChat = {
  name: 'live-chat',

  computed: {
    buttonChatStyle() {
      return this.$store.state.siteInfo.buttonChatStyle;
    },
  },

  mounted() {
    const promise = new Promise((resolve) => {
      const chatSnippetScript = document.createElement('script');
      chatSnippetScript.setAttribute('type', 'text/javascript');
      chatSnippetScript.setAttribute('src', 'https://service.force.com/embeddedservice/5.0/esw.min.js');
      document.body.appendChild(chatSnippetScript);
      chatSnippetScript.onload = () => {
        resolve('result');
      };
    });

    promise
      .then(
        (result) => {
          const buttonChatStyle = this.buttonChatStyle;
          const chatSnippetScript = document.createElement('script');
          chatSnippetScript.setAttribute('type', 'text/javascript');
          chatSnippetScript.innerHTML = buttonChatStyle.chatTemplate;
          setTimeout(() => {
            document.body.appendChild(chatSnippetScript);
          }, 0);
          setTimeout(() => {
            const embeddedServiceHelpButton = document.querySelector('.embeddedServiceHelpButton');
            if (embeddedServiceHelpButton !== null) {
              const liveChatHolder = document.querySelector('.live-chat-holder');
              liveChatHolder.append(embeddedServiceHelpButton);

              const snippetButton = document.getElementsByClassName('uiButton').item(0);
              if (snippetButton) {
                snippetButton.style.setProperty('color', this.buttonChatStyle.color, 'important');
                snippetButton.style.setProperty('background-color', this.buttonChatStyle.backgroundColor, 'important');
              }
            }
          }, 1800);
        },
      );
  },
};

export default LiveChat;
</script>
