function createAliasPayload(alias) {
  const payload = {
    lower: alias.toLowerCase(),
    sentence: alias.split(' ')
      .map(x => x[0].toUpperCase() + x.substr(1).toLowerCase())
      .join(' '),
    upper: alias.toUpperCase(),
  };
  return payload;
}

export const ui = {
  namespaced: true,
  state: {
    sidebarOpen: false,
    isLoggingOut: false,
    isLoadingPlan: false,
    isNavigating: false,
    allMenuItems: [
      { id: 'dashboard', title: 'Dashboard', link: '' },
      { id: 'deviceManagement', title: 'Device Management', link: 'device-management' },
      { id: 'manageYourPlan', title: 'My Plan', link: 'manage-your-plan' },
      { id: 'billingInformation', title: 'Billing Information', link: 'billing-information' },
      { id: 'myAccount', title: 'Account Details', link: 'my-account' },
      { id: 'preferencesAndPrivacy', title: 'Preferences & Privacy', link: 'preferences-and-privacy' },
    ],
    siteAlias: createAliasPayload('site'),
    siteAliasPlural: createAliasPayload('sites'),
    buildingAlias: createAliasPayload('building'),
    buildingAliasPlural: createAliasPayload('buildings'),
    zoneAlias: createAliasPayload('zone'),
    zoneAliasPlural: createAliasPayload('zones'),
    accessPeriodAlias: createAliasPayload('access period'),
    accessPeriodAliasPlural: createAliasPayload('access periods'),
    planAlias: createAliasPayload('plan'),
    planAliasPlural: createAliasPayload('plans'),
    siteLang: 'en',
  },
  getters: {
    menuItems(state, getters, rootState, rootGetters) {
      let items = state.allMenuItems;
      let visibleTabs = [];
      const portalType = rootState.siteInfo.portalType;

      switch (portalType) {
        case 'FREE_SIGN_UP':
        case 'NAAS':
        case 'ANCHOR':
          visibleTabs = ['deviceManagement', 'myAccount', 'preferencesAndPrivacy'];
          break;
        default:
          visibleTabs = ['dashboard', 'deviceManagement', 'manageYourPlan', 'billingInformation', 'myAccount', 'preferencesAndPrivacy'];
      }

      const theme = rootState.siteInfo.siteCssInfoResponse.name;
      items = items.filter(x => visibleTabs.includes(x.id));

      if (theme === 'parkconnect') {
        items = items.filter(x => x.id !== 'preferencesAndPrivacy');
      }

      if (rootGetters['siteInfo/isAnchor']) {
        items = items.filter(x => visibleTabs.includes(x.id));
        items = items.map((x) => {
          if (x.id === 'deviceManagement') {
            return { ...x, title: 'Personal Network' };
          }
          return x;
        });

        return items;
      }

      if (rootState.siteInfo.user && !rootState.siteInfo.user.hasActiveSubscription && !rootState.siteInfo.user.paused) {
        items = items.filter(x => x.id !== 'manageYourPlan');
      }

      const isRecurring = rootState.siteInfo?.user?.hasActiveSubscription && rootState.siteInfo?.user?.hasRecurringSub;
      if (!isRecurring) {
        items = items.filter(x => x.id !== 'dashboard' && x.id !== 'billingInformation');
      }

      return items;
    },
  },
  actions: {
    toggleSidebar({ commit }) {
      commit('toggleSidebar');
    },
    closeSidebar({ commit }) {
      commit('closeSidebar');
    },
    setIsLoggingOut({ commit }, isLoggingOut) {
      commit('setIsLoggingOut', isLoggingOut);
    },
    setIsLoadingPlan({ commit }, isLoadingPlan) {
      commit('setIsLoadingPlan', isLoadingPlan);
    },
    setAliases({ commit }, dictionary) {
      if (dictionary.site && dictionary.sites) {
        commit('upsertSiteAlias', {
          singular: dictionary.site,
          plural: dictionary.sites,
        });
      }

      if (dictionary.building && dictionary.buildings) {
        commit('upsertBuildingAlias', {
          singular: dictionary.building,
          plural: dictionary.buildings,
        });
      }

      if (dictionary.zone && dictionary.zones) {
        commit('upsertZoneAlias', {
          singular: dictionary.zone,
          plural: dictionary.zones,
        });
      }

      if (dictionary.accessPeriod && dictionary.accessPeriods) {
        commit('upsertAccessPeriodAlias', {
          singular: dictionary.accessPeriod,
          plural: dictionary.accessPeriods,
        });
      }
    },
    startNavigation({ commit }) {
      commit('setIsNavigating', true);
    },
    stopNavigation({ commit }) {
      commit('setIsNavigating', false);
    },
  },
  mutations: {
    setLang(state, lang) {
      state.siteLang = lang;
    },
    toggleSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    closeSidebar(state) {
      state.sidebarOpen = false;
    },
    setIsLoggingOut(state, isLoggingOut) {
      state.isLoggingOut = isLoggingOut;
    },
    setIsLoadingPlan(state, isLoadingPlan) {
      state.isLoadingPlan = isLoadingPlan;
    },
    setIsNavigating(state, isNavigating) {
      state.isNavigating = isNavigating;
    },
    upsertSiteAlias(state, data) {
      state.siteAlias = createAliasPayload(data.singular);
      state.siteAliasPlural = createAliasPayload(data.plural);
    },
    upsertBuildingAlias(state, data) {
      state.buildingAlias = createAliasPayload(data.singular);
      state.buildingAliasPlural = createAliasPayload(data.plural);
    },
    upsertZoneAlias(state, data) {
      state.zoneAlias = createAliasPayload(data.singular);
      state.zoneAliasPlural = createAliasPayload(data.plural);
    },
    upsertAccessPeriodAlias(state, data) {
      state.accessPeriodAlias = createAliasPayload(data.singular);
      state.accessPeriodAliasPlural = createAliasPayload(data.plural);
    },
  },
};
